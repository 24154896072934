import React from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";

const StyledDropzone = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "",
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: "2px dashed #1976d2",
        borderRadius: 2,
        padding: 4,
        textAlign: "center",
        backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
        color: "#1976d2",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#e3f2fd",
        },
      }}
    >
      <input {...getInputProps()} />
      <Typography variant="body1" sx={{ mb: 1 }}>
        {isDragActive ? "Suelta los archivos aquí" : "Arrastra y suelta archivos aquí, o haz clic para seleccionar"}
      </Typography>
      <Typography variant="caption">
        Puedes cargar imágenes, documentos PDF u otros archivos compatibles
      </Typography>
    </Box>
  );
};

export default StyledDropzone;
